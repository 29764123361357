import { ref, onMounted, watch, Ref } from "vue"
import { t } from "@/translation"

export type RequestElement = {
  title: string,
  description: string,
  header?: {
    icon?: string,
    color?: string,
    background?: string,
  },
  events?:{
    onOpen?: (id: string) => void,
    onClose?: () => void,
  },
  actions : { title: string, call: (values: any) => void, color: string | 'gray' | 'red' | 'blue', validator: () => boolean }[]
}

export const requests : Ref<RequestElement[]> = ref([])

export const request : Ref<undefined | RequestElement> = ref(undefined);

export type ConfirmationOption = {
  mode? : "info" | "danger" | "warning";
  actionName? : string;
  icon?: string;
  validator?: () => boolean,
  events?:{
    onOpen?: (id: string) => void,
    onClose?: () => void,
  }
}

const visuals = {
  info : {
    icon: 'fi-rr-info',
    color: 'gpblue',
    btnColor: 'blue',
  },
  danger : {
    icon: 'fi-rr-info',
    color: 'red-600',
    btnColor: 'red',
  },
  warning : {
    icon: 'fi-rr-info',
    color: 'orange-600',
    btnColor: 'orange',
  }
}

export function RequestConfirmation(title: string, description: string, options: ConfirmationOption = {}){
  const visual = visuals[options.mode ?? 'info'];

  return new Promise((res, rej) => {
    PushRequest({
      title,
      description,
      header: {
        icon: options.icon ?? visual.icon,
        color: visual.color,
      },
      events:{
        onOpen: options?.events?.onOpen,
        onClose: options?.events?.onClose,
      },
      actions : [
        { title : t('cancel'), call: rej, color: 'gray', validator: () => true },
        { title : options.actionName ?? t('accept'), call: res, color: visual.btnColor, validator: options.validator ?? (() => true) },
      ],
    });
  })
}

export function PushRequest(newRequest: RequestElement){
  requests.value.push(newRequest);
}